<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <div class="title-container">
                    <h1 class="h1">Gestão de Suprimentos</h1>
                </div>
            </v-col>
        </v-row>
        <v-tabs v-model="tab" @change="onTabChange">
            <!-- <v-tab>Equipamentos</v-tab> -->
            <v-tab>Equipamentos</v-tab>
            <v-tab>Esterilizáveis</v-tab>
            <v-tab>Cadastro Geral</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <!-- <v-tab-item>
                <ControleEquipamento/>
            </v-tab-item> -->
            <v-tab-item>
                <ControleEquipamentos ref="equipamentoTab" />
            </v-tab-item>
            <v-tab-item>
                <ControleEsterelizavel ref="esterilizavelTab" />
            </v-tab-item>
             <v-tab-item>
                <v-tabs v-model="subTab" class="mt-4">
                    <v-tab>Medicamento</v-tab>
                    <v-tab>Material</v-tab>
                    <v-tab>Dieta</v-tab>
                    <v-tab>Equipamentos</v-tab>
                    <v-tab>Esterilizáveis</v-tab>
                    <v-tab>Kit's</v-tab>
                    <v-tab>Equipamentos Dependentes</v-tab>
                    <v-tab>Fornecedores</v-tab>
                    <v-tab>Nots Fiscais</v-tab>
                    <v-tab>Notas de Esterilização</v-tab>
                    <v-tab>Endereços Estoque</v-tab>
                </v-tabs>
                <v-tabs-items v-model="subTab">
                    <v-tab-item>
                        <Medicamento />
                    </v-tab-item>
                    <v-tab-item>
                        <Material />
                    </v-tab-item>
                    <v-tab-item>
                        <Dieta />
                    </v-tab-item>
                    <v-tab-item>
                        <Equipamentos />
                    </v-tab-item>
                    <v-tab-item>
                        <Esterelizavel />
                    </v-tab-item>
                    <v-tab-item>
                        <Kit/>
                    </v-tab-item>
                    <v-tab-item>
                        <Dependencia/>
                    </v-tab-item>
                    <v-tab-item>
                        <Fornecedores/>
                    </v-tab-item>
                    <v-tab-item>
                        <NotaFiscal/>
                    </v-tab-item>
                    <v-tab-item>
                        <Esterilizacao/>
                    </v-tab-item>
                    <v-tab-item>
                        <Estoque/>
                    </v-tab-item>
                </v-tabs-items>
            </v-tab-item>
        </v-tabs-items>
    </v-container>
</template>

<script>
import '../../assets/css/styles.css'

export default {
    name: 'GestaoEquipamento',
    components: {
        // ControleEquipamento: () => import("./controleEquipamento/Controle.vue"),
        ControleEquipamentos: () => import("./controleEquipamentos/Index.vue"),
        ControleEsterelizavel: () => import("./controleEsterelizavel/Index.vue"),
        Equipamentos: () => import("./cadastro/equipamento/index.vue"),
        Medicamento: () => import("./cadastro/matmed/indexMedicamento.vue"),
        Material: () => import("./cadastro/matmed/indexMaterial.vue"),
        Dieta: () => import("./cadastro/matmed/indexDieta.vue"),
        Esterelizavel: () => import('./cadastro/esterelizavel/index.vue'),
        Kit: () => import('./cadastro/kit/index.vue'),
        Dependencia: () => import('./cadastro/equipamentoDependente/index.vue'),
        Fornecedores: () =>import('./cadastro/fornecedores/index.vue'),
        NotaFiscal:() =>import('./cadastro/notaFiscal/NotaFiscal.vue'),
        Esterilizacao:() =>import('./cadastro/esterelizacao/Esterilização.vue'),
        Estoque:() =>import('./cadastro/EnderecoEstoque/index.vue'),
    },
    data: () => ({
        tab: 0,
        subTab:0,
    }),
    methods: {
    onTabChange(selectedTabIndex) {
      // console.log("Refs disponíveis:", this.$refs);
      if (selectedTabIndex === 0 && this.$refs.equipamentoTab) {
        // console.log("Inicializando Equipamento...");
        this.$refs.equipamentoTab.carregarDadosIniciais();
      } else if (selectedTabIndex === 1 && this.$refs.esterilizavelTab) {
        // console.log("Inicializando Esterilizável...");
        this.$refs.esterilizavelTab.carregarDadosIniciais();
      }
    },
  },
}
</script>

<style scoped>
.title-container {
  display: flex;
  align-items: center;
  justify-content: center; /* Centraliza os elementos horizontalmente */
  flex-wrap: nowrap; /* Impede que os elementos sejam quebrados em linhas diferentes */
}

.h1 {
  margin: 0; /* Remove margens padrão para melhor alinhamento */
  flex-shrink: 0; /* Impede que o título encolha, forçando a badge a se mover para baixo */
}

</style>